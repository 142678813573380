import React from "react"
import { StoryGroup } from "components"
import { flatStoryGroup } from "utils/array-util"

const eBooks = [
  {
    title: "Basitten - Karmaşığa Frontend Uygulamaları",
    url:
      "https://learnreactui.dev/contents/veri-kullanimi-acisindan-basitten-karmasiga-fe-uygulamalari",
    img:
      "https://storage.googleapis.com/argon-app-web-prod/assets%2Fe5d2d417-fd53-4579-af19-213b74eb324a%2Fcovers%2FVPUK_Cover4.png",
  },
  {
    title: "Simple to Complex Frontend Applications",
    url:
      "https://learnreactui.dev/contents/simple-to-complex-frontend-applications",
    img:
      "https://storage.googleapis.com/argon-app-web-prod/assets%2F1f5035d0-92a6-453d-bf51-3aee3a6c0d2a%2Fcovers%2FSCFA_Cover2.png",
  },
  {
    title: "Legacy React",
    url: "https://learnreactui.dev/contents/legacy-react",
    img:
      "https://storage.googleapis.com/argon-app-web-prod/assets%2Faba808bf-eace-4fac-b8dd-b9c6e296ec80%2Fcovers%2FCover.png",
  },
  {
    title: "Legacy React (Advanced)",
    url: "https://learnreactui.dev/contents/legacy-react-advanced",
    img:
      "https://storage.googleapis.com/argon-app-web-prod/assets%2F5e58a758-5834-4caa-8e0e-8c6ba1a87b51%2Fcovers%2FCover2.png",
  },
]

export const LinkMap = {
  //Concept
  MVC: "17cb8810b836",
  Flux: "17cb8810b836",
  JSX: "17cb8810b836",
  Templates: "17cb8810b836",
  "Virtual DOM": "17cb8810b836",
  Reconcilation: "17cb8810b836",

  "Rendering Elements": "171a51e261b2",
  ReactDOM: "171a51e261b2",
  Components: "171a51e261b2",
  "Class Components": "171a51e261b2",
  "Functional Components": "171a51e261b2",
  Props: "171a51e261b2",
  State: "171a51e261b2",
  Lifecycle: "171a51e261b2",
  SyntheticEvent: "171a51e261b2",
  "Conditional Rendering": "171a51e261b2",
  "Lists and Keys": "171a51e261b2",
  Forms: "171a51e261b2",
  "Lifting State Up": "171a51e261b2",
  Composition: "171a51e261b2",
  Inheritance: "171a51e261b2",

  Portal: "4fa609d6eea5",
  Ref: "4fa609d6eea5",
  Context: "4fa609d6eea5",
  Consumer: "4fa609d6eea5",
  Provider: "4fa609d6eea5",

  HOC: "947b4fdbba7e",
  "High Order Components": "947b4fdbba7e",
  "Render Props": "947b4fdbba7e",

  "Semantic UI": "c6ece96f9cac",
  Dropdown: "c6ece96f9cac",

  "Long Processing": "473723674022",
  "Main Tread": "473723674022",

  "serve -s build": "8799347e2d3f",

  "Redux Connect": "23aa1738a7ba",
  "Lazy Load": "23aa1738a7ba",

  "React-Forms": "73d063a6949e",
  "Form-Reset": "73d063a6949e",
  "Form-Validate": "73d063a6949e",
  "Form-Apply": "73d063a6949e",
  "Form-Show Error": "73d063a6949e",
  "Form-Controlled": "73d063a6949e",
  "Form-UnControlled": "73d063a6949e",

  PropTypes: "b37bab2def51",
  "PropTypes.requiredFunc": "b37bab2def51",
  "PropTypes.requiredAny": "b37bab2def51",

  "Why Do We Write super(props)": "b10a759a73fc",
  "How Does React Tell a Class from a Function?": "b10a759a73fc",
  "Why Do React Elements Have a $$typeof Property?": "b10a759a73fc",
  "My Wishlist for Hot Reloading": "b10a759a73fc",
  "How Does setState Know What to Do?": "b10a759a73fc",
  "Optimized for Change?": "b10a759a73fc",
  "Why Do React Hooks Rely on Call Order?": "b10a759a73fc",
  "The Elements of UI Engineering?": "b10a759a73fc",
  "The “Bug-O” Notation": "b10a759a73fc",
  "Why Isn’t X a Hook?": "b10a759a73fc",
  "React as a UI Runtime": "b10a759a73fc",
  "Making setInterval Declarative with React Hooks": "b10a759a73fc",
  "Fix Like No One’s Watching": "b10a759a73fc",
  "Coping with Feedback": "b10a759a73fc",
  "How Are Function Components Different from Classes?": "b10a759a73fc",
  "A Complete Guide to useEffect": "b10a759a73fc",
  "Writing Resilient Components": "b10a759a73fc",
  "Name It, and They Will Come": "b10a759a73fc",
  "Algebraic Effects for the Rest of Us": "b10a759a73fc",
  "How Does the Development Mode Work?": "b10a759a73fc",
  "What Is JavaScript Made Of?": "b10a759a73fc",
  "On let vs const": "b10a759a73fc",
  "What Are the React Team Principles?": "b10a759a73fc",
  "My Decade in Review": "b10a759a73fc",
  "Goodbye, Clean Code": "b10a759a73fc",
  "The WET Codebase": "b10a759a73fc",

  createRef: "1e0cc06f22f0",

  "this.handleClick.bind(this)": "6c26f63a6ea5",
  "arrow function handling": "6c26f63a6ea5",

  "Code Sharing": "999991961ff",
  Inheritance2: "999991961ff",
  "HOC(High Order Component)": "999991961ff",
  "Render Props2": "999991961ff",
  Hooks2: "999991961ff",

  "HTML Head": "b2b4e8564b80",
  "Head-title": "b2b4e8564b80",
  "Head-meta": "b2b4e8564b80",
  "Head-link": "b2b4e8564b80",
  "Head-style": "b2b4e8564b80",
  "Head-script": "b2b4e8564b80",

  "props.children": "41f832adc103",
  "Component Composition": "41f832adc103",

  import: "1a0b39c0efe0",
  "React.lazy": "1a0b39c0efe0",

  "React API": "a19a2a3dbf0a",
  "ReactDOM API": "a19a2a3dbf0a",
  "ReactDOMServer API": "a19a2a3dbf0a",
  "ReactTestRenderer API": "a19a2a3dbf0a",
  "ReactTestUtilities API": "a19a2a3dbf0a",
  "DOM Elements": "a19a2a3dbf0a",
  "Synthetic Events": "a19a2a3dbf0a",
  "Mimari ve Design(Figma)": "8b2a2fd87045",
}

const storiesDataAccessTypes = [
  {
    title: "React Projeleri Zorlugu -1 (Basit)",
    postId: "3d35714726f5",
  },
  {
    title: "React Projeleri Zorlugu -2 (Orta)",
    postId: "63c7a112efaa",
  },
  {
    title: "React Projeleri Zorlugu -3 (Zor)",
    postId: "6905a3e6c2d4",
  },
  {
    title: "React Projeleri Zorlugu -4 (Çok Zor)",
    postId: "cd3c413161e9",
  },
]

const storiesTips = [
  {
    title: "Semantic UI React Dropdown",
    postId: "c6ece96f9cac",
  },
  {
    title: "Input Alanları UI Bloklar",
    postId: "473723674022",
  },
  {
    title: "React Build'ini Local’de Çalıştırma",
    postId: "8799347e2d3f",
  },
  {
    title: "React.JS için Küçük İpuçları",
    postId: "23aa1738a7ba",
  },
  {
    title: "Overreacted.io Üzerine Notlar",
    postId: "b10a759a73fc",
  },
  {
    title: "Action Handler Binding",
    postId: "6c26f63a6ea5",
  },
  {
    title: "HTML Title ve Meta Verisi Güncelle",
    postId: "b2b4e8564b80",
  },
  {
    title: "NPM Paketlerini Dinamik Ekleme",
    postId: "1a0b39c0efe0",
  },
  {
    title: "Dosya Download Ettirme",
    postId: "6b6909f5cc2d",
  },
  {
    title: "Quick Search With CMD+K",
    postId: "795ac49e4257",
  },
  {
    title: "List Navigation Keyboard Shortcuts",
    postId: "197d5d4880b2",
  },
  {
    title: "Sayfada Istenilen Yere Scroll Etme",
    postId: "c60cdde9d61c",
  },
]

const storiesBasics = [
  {
    title: "React Nedir",
    postId: "a19a2a3dbf0a",
  },
  {
    title: "React Best Practices",
    postId: "17cb8810b836",
  },
  {
    title: "React Docs(Temel Konseptler)",
    postId: "171a51e261b2",
  },
]

const storiesAdvanced = [
  {
    title: "React Docs(Forms)",
    postId: "73d063a6949e",
  },
  {
    title: "React Docs(Ref Usage)",
    postId: "1e0cc06f22f0",
  },
  {
    title: "React Docs(DOM Tree Problems)",
    postId: "4fa609d6eea5",
  },
  {
    title: "React Docs(Code Sharing)",
    postId: "947b4fdbba7e",
  },
  {
    title: "React (Code Sharing) Örnekleri",
    postId: "999991961ff",
  },
  {
    title: "React props.children kullanımı",
    postId: "41f832adc103",
  },
  {
    title: "React Bileşenlerinde Prop Check",
    postId: "b37bab2def51",
  },
]

const storiesDeep = [
  {
    title: "RSC Nedir ",
    postId: "6357924b1125",
  },
  {
    title: "React Boundaries ",
    postId: "e2d2b69eea16",
  },
  {
    title: "React Forget Compiler",
    postId: "6609947497f2",
  },
  {
    title: "React Fiber Nedir",
    postId: "274be4fbe44f",
  },
]

const storiesGroup = [
  {
    title: "Temel",
    storyPart: storiesBasics,
  },
  {
    title: "Advanced",
    storyPart: storiesAdvanced,
  },
  {
    title: "Deep Dive",
    storyPart: storiesDeep,
  },
  {
    title: "Veri Erişimi",
    storyPart: storiesDataAccessTypes,
  },
  {
    title: "Tips (İpuçları)",
    storyPart: storiesTips,
  },
]

export const context = {
  stories: flatStoryGroup(storiesGroup),
  linkMap: LinkMap,
  title: "React Basics",
  path: "react",
}

const ReactPage = () => {
  return (
    <StoryGroup
      title={context.title}
      complex={true}
      stories={storiesGroup}
      linkMap={context.linkMap}
      eBooks={eBooks}
    />
  )
}

export default ReactPage
